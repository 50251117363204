import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";

import axios from "@axios";
import { useRouter } from "@core/utils";
import store from "@/store";
import { computed } from "@vue/composition-api";

/**
 * Helps manage several axios calls on a same page.
 *
 * It centralize axios actions, logs URLs being loaded
 * and provides a isLoading state until all URLs have not finished to load.
 */
const useAxios = () => {
  const { route } = useRouter();
  const page = route.value.path.replace(/^(\/)?/, "").replaceAll("/", "_");
  const supportedMethods = ["get", "post", "put", "patch", "delete"];

  const axiosGet = async (url, params, options = {}) => {
    addLoading("get", url);
    const result = await axios.get(url, { params, ...options });
    removeLoading("get", url);

    return result;
  };

  const axiosPost = async (url, params, options = undefined) => {
    addLoading("post", url);
    const result = await axios.post(url, params, options);
    removeLoading("post", url);

    return result;
  };

  const axiosPut = async (url, params) => {
    addLoading("put", url);
    const result = await axios.put(url, params);
    removeLoading("put", url);

    return result;
  };

  const axiosPatch = async (url, params) => {
    addLoading("patch", url);
    const result = await axios.patch(url, params);
    removeLoading("patch", url);

    return result;
  };

  const axiosDelete = async (url, params) => {
    addLoading("delete", url);
    const result = await axios.delete(url, { params });
    removeLoading("delete", url);

    return result;
  };

  const isLoading = computed(() => {
    return supportedMethods
      .map(
        (method) =>
          !isEmpty(
            get(
              store.getters["axios/getLoadingUrls"],
              `${page}.${method}`,
              undefined
            )
          )
      )
      .some((value) => value);
  });

  const addLoading = (method, url) => {
    store.dispatch("axios/setLoadingUrls", {
      name: `${page}.${method}`,
      value: (state) => uniq([...(state ?? []), url]),
    });
  };

  const removeLoading = (method, url) => {
    store.dispatch("axios/setLoadingUrls", {
      name: `${page}.${method}`,
      value: (state) => [...(state ?? []).filter((link) => link !== url)],
    });
  };

  return {
    isLoading,
    axiosGet,
    axiosPost,
    axiosPut,
    axiosPatch,
    axiosDelete,
  };
};

export default useAxios;
