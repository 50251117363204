<template>
  <div>
    <v-autocomplete
      v-model="selectedYear"
      outlined
      dense
      label="Années"
      :items="years"
      :prepend-inner-icon="icons.mdiCalendar"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";

export default {
  data() {
    return {
      menu: false,
      icons: {
        mdiCalendar,
      },
      years: [],
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {},
  computed: {
    selectedYear: {
      get() {
        return this.$store.getters["audience/getYearDate"];
      },
      set(e) {
        this.$store.dispatch("audience/updateYearDate", e);
      },
    },
  },
  async created() {
    for (let i = 2020; i <= this.currentYear; i++) {
      this.years.push(i);
    }
  },
};
</script>

<style></style>
