<template>
  <div>
    <p class="text-2xl mb-6">Audience par source</p>
    <v-card class="pt-8">
      <filters></filters>
    </v-card>

    <v-card class="mt-8">
      <v-card-text class="d-flex flex-wrap pb-4">
        <div class="results-container d-flex flex-row">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
            class="elevation-1 results-table"
          >
          </v-data-table>
          <dot-menu
            @exportCsv="exportCSV"
            :csvButton="{
              csvData,
              filename: `export_${this.getYear}_audience_source.csv`,
            }"
          />
        </div>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
        Vous recherchez les sources contenant :
        <strong>{{ this.getContainsSource }}</strong>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import Filters from "@/components/audience/by-source/filters/Filters.vue";
import DotMenu from "@/components/common/menus/DotMenu";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "AudienceParSource",
  components: {
    DotMenu,
    Filters,
  },
  data() {
    return {
      items: [],
      snackbar: false,
      timeout: 3000,
      csvData: "",
      loading: false,
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  methods: {
    getEndpointParams() {
      if (
        this.getSites.length > 0 &&
        this.getYear &&
        this.sourceMediumId.length > 0
      ) {
        return {
          start: this.start,
          end: this.end,
          source_medium_id: this.sourceMediumId,
          sites_id: this.getSites.join(","),
        };
      }

      return null;
    },
    async getData() {
      const params = this.getEndpointParams();
      if (this.applyFilters && !this.loading && params) {
        this.loading = true;
        const response = await this.axiosGet("/ga-source-sessions/", params);

        this.loading = false;

        this.items = response.data.map((source) => {
          const site = this.getAllSites.find(
            (_site) => _site.id === source.site_id
          );

          return {
            ...source,
            site_name: site?.name ?? "",
            selected: true,
          };
        });

        if (this.getExactSearch === true) {
          this.snackbar = true;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },

    updateYear() {
      this.start = `${this.getYear}-01-01`;
      this.end = `${this.getYear}-12-31`;
    },

    async exportCSV() {
      const params = this.getEndpointParams();
      if (params) {
        const { data } = await this.axiosGet(
          "/ga-source-sessions/export",
          params
        );

        this.csvData = data;
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    getSites() {
      return this.$store.getters["audience/getSites"];
    },
    getAllSites() {
      return this.$store.getters["common/getSiteList"];
    },
    getYear() {
      return this.$store.getters["audience/getYearDate"];
    },
    getSourceId() {
      return this.$store.getters["audience/getSourceId"];
    },
    getListSourceId() {
      return this.$store.getters["audience/getListSourceId"];
    },
    getExactSearch() {
      return this.$store.getters["audience/getExactSearch"];
    },
    getContainsSource() {
      return this.$store.getters["audience/getContainsSource"];
    },
    sourceMediumId() {
      return this.getExactSearch
        ? this.getListSourceId.join(",")
        : this.getSourceId.toString();
    },
    headers() {
      return [
        { text: "Site", value: "site_name" },
        { text: "Janvier", value: `${this.getYear}-01` },
        { text: "Février", value: `${this.getYear}-02` },
        { text: "Mars", value: `${this.getYear}-03` },
        { text: "Avril", value: `${this.getYear}-04` },
        { text: "Mai", value: `${this.getYear}-05` },
        { text: "Juin", value: `${this.getYear}-06` },
        { text: "Juillet", value: `${this.getYear}-07` },
        { text: "Août", value: `${this.getYear}-08` },
        { text: "Septembre", value: `${this.getYear}-09` },
        { text: "Octobre", value: `${this.getYear}-10` },
        { text: "Novembre", value: `${this.getYear}-11` },
        { text: "Décembre", value: `${this.getYear}-12` },
      ];
    },
  },
  watch: {
    getYear: {
      handler: function () {
        this.updateYear();
      },
      immediate: true,
    },
    applyFilters() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.results-container,
.results-table {
  width: 100%;
}
</style>
