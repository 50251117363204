<template>
  <v-btn
    @click="setApplyFilters"
    :class="classList"
    :style="styles"
    :outlined="outlined"
    :disabled="disabled"
    :color="color"
  >
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: "ApplyFilters",
  props: {
    title: {
      type: String,
      default: "Rechercher",
    },
    classList: {
      type: String,
      default: "pt-7 pb-6 mt-0 my-1",
    },
    styles: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("common/updateApplyFilters", false);
  },
  methods: {
    setApplyFilters() {
      this.$store.dispatch("common/updateApplyFilters", true);
    },
  },
};
</script>

<style></style>
