<template>
  <div>
    <v-row>
      <v-col class="pr-0 mr-0" cols="1">
        <v-checkbox
          v-model="exactSearch"
          color="primary"
          outlined
          dense
          @change="
            {
              sourceObject = null;
              containsSource = '';
            }
          "
        ></v-checkbox>
      </v-col>
      <v-col class="pl-0 ml-n2">
        <v-text-field
          v-if="exactSearch"
          item-text="name"
          item-value="id"
          v-model="containsSource"
          outlined
          dense
          label="Contenant"
          hint="Cliquez sur l'icon ou appuyez sur Entrée"
          persistent-hint
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          :prepend-inner-icon="icons.mdiEye"
          v-on:keyup="debouncedFetchSource"
          @click:prepend-inner="showSources"
        >
        </v-text-field>

        <v-autocomplete
          v-else
          v-model="selectedSource"
          item-text="name"
          item-value="id"
          :search-input.sync="searchSource"
          v-on:keyup="debouncedFetchSource"
          :items="sourceObject"
          label="Recherche"
          hint="'Sources parmis la liste'"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          persistent-hint
          outlined
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-dialog scrollable v-model="dialogVisible" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">Sources</span>
        </v-card-title>
        <div>
          <v-btn class="ml-4 mb-4" color="primary" small @click="selectAll">
            {{ selectAllChecked ? "Tout désélectionner" : "Tout sélectionner" }}
          </v-btn>
        </div>
        <v-divider></v-divider>
        <v-card-text>
          <ul class="no-bullets-list">
            <v-row>
              <v-col
                v-for="source in sourceObject"
                :key="source.id"
                cols="3"
                class="d-flex align-center"
              >
                <li class="d-flex align-center">
                  <v-checkbox
                    color="primary"
                    outlined
                    dense
                    v-model="source.selected"
                    @change="changeListId()"
                  ></v-checkbox>
                  <span class="mb-3">{{ source.name }}</span>
                </li>
              </v-col>
            </v-row>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dialogVisible = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@axios";
import { mdiArrowRightBox, mdiEye } from "@mdi/js";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      icons: {
        mdiArrowRightBox,
        mdiEye,
      },
      sourceId: [],
      searchSource: "",
      sourceObject: null,
      dialogVisible: false,
      selectAllChecked: true,
    };
  },
  computed: {
    selectedSource: {
      get() {},
      set(sourceId) {
        this.$store.dispatch("audience/updateSourceId", sourceId);
      },
    },
    listSourceId: {
      get() {
        return this.$store.getters["audience/getListSourceId"];
      },
      set(listSourceId) {
        this.$store.dispatch("audience/updateListSourceId", listSourceId);
      },
    },
    exactSearch: {
      get() {
        return this.$store.getters["audience/getExactSearch"];
      },
      set(e) {
        this.$store.dispatch("audience/updateExactSearch", e);
      },
    },
    containsSource: {
      get() {
        return this.$store.getters["audience/getContainsSource"];
      },
      set(e) {
        this.$store.dispatch("audience/updateContainsSource", e);
      },
    },
  },
  methods: {
    debouncedFetchSource: debounce(async function () {
      await this.fetchSource();
    }, 500),
    async fetchSource() {
      this.sourceId = [];
      const sourceCatch = this.exactSearch
        ? this.containsSource
        : this.searchSource;
      if (!sourceCatch) {
        return;
      }

      const response = await axios.get("/ga-dimension-source-medium/", {
        params: {
          medium_source_name: sourceCatch,
          is_exact: false,
        },
      });
      this.sourceObject = response.data.map((source) => ({
        ...source,
        selected: true,
      }));
      this.changeListId();
    },

    selectAll() {
      let selectAllValue = this.selectAllChecked;
      this.sourceObject.forEach((source) => {
        source.selected = !selectAllValue;
      });
      this.selectAllChecked = !this.selectAllChecked;
      this.changeListId();
    },
    changeListId() {
      this.listSourceId = this.sourceObject
        .filter((item) => item.selected)
        .map((item) => item.id);
    },
    showSources() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.no-bullets-list {
  list-style: none;
}

.no-bullets-list .col {
  overflow: hidden;
  padding: 0;
}
</style>
