let rmrUiToken = "52894305a7a944f6890c616e0a9c721a";
let rmrAnalyticsPath = "https://analytics.rmr.reworldmediafactory.com/event";
let isProd = window.location.hostname.includes("reworldmediafactory");
if (!isProd) {
  rmrAnalyticsPath = "http://localhost:3000/event";
}

const saveRmraEvent = (eventType, eventCategory) => {
  fetch(rmrAnalyticsPath, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: rmrUiToken,
      eventType: eventType,
      eventCategory: eventCategory,
    }),
  });
};

export const saveRmraView = (route) => {
  const abilities = JSON.parse(localStorage.getItem("userAbility"));
  if (abilities && abilities.some((ability) => ability.subject === "no_logging")) {
    return;
  }
  if (route.meta.rmrAnalytics && route.meta.rmrAnalytics.track) {
    console.log("saveRmraView", route.meta.rmrAnalytics);
    saveRmraEvent(
      route.meta.rmrAnalytics.eventType,
      route.meta.rmrAnalytics.eventCategory
    );
  }
};

export const saveRmraCustomEvent = (eventType, eventCategory) => {
  saveRmraEvent(eventType, eventCategory);
}
